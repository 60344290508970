// @flow strict

import { message } from "antd";
import CodeVerificationWidget from "components/CodeVerificationWidget/CodeVerificationWidget";
import LocationInputTextWidget from "components/LocationInputTextWidget/LocationInputTextWidget";

import { REGISTER_WITH } from "config/CONSTANTS";
import { useAuth } from "navigation/Auth/ProvideAuth";
import {
  ROUTE_DASHBOARD,
} from "navigation/CONSTANTS";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, withRouter } from "react-router";
import { updateUser } from "redux/actions/userActions";
import InputPage from "./InputPage";

const LOGIN_PAGES = {
  InputPage: "InputPage",
  ActivationCodePage: "ActivationCodePage",
  InputLocationPage: "InputLocationPage",
};
const pageList = [
  LOGIN_PAGES.InputPage,
  LOGIN_PAGES.ActivationCodePage,
  LOGIN_PAGES.InputLocationPage,
];
function LoginPage(props) {
  const appDataStore = useSelector(x => x.appDataStore);
  const location = useLocation();
  const auth = useAuth();
  const dispatch = useDispatch();

  const [currentPage, setCurrentpage] = useState(0);
  const [userDetail, setUserDetail] = useState({
    register_with: REGISTER_WITH.email,
  });

  useEffect(() => {
    dispatch(updateUser({ token: undefined }));
  }, []);

  const onClickLogin = () => {

    auth
      .signin(userDetail)
      .then((res) => {
        const { token } = res;
        onSuccessLogin(res);
    
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const onClickRegister = () => {
  
  };

  const goBackPage = () => {
    setCurrentpage(currentPage - 1);
  };
  const goNextPage = () => {
    setCurrentpage(currentPage + 1);
  };
  const onSuccessLogin = (info) => {
    if (info.user === undefined || info.token === undefined) {
      return;
    }
    dispatch(updateUser(info));


    const { from } = location.state || { from: { pathname: appDataStore.currentRoute === undefined ? ROUTE_DASHBOARD : appDataStore.currentRoute } };

    setTimeout(() => {
      props.history.replace(from.pathname == "/login"?{pathname:ROUTE_DASHBOARD}:from);
    }, 500);
  };
  return (
    <div style={{ color: 'var(--blackColor)' }}>
      <div
        style={{
          display:
            pageList[currentPage] === LOGIN_PAGES.InputPage ? "block" : "none",
        }}
      >
        <InputPage
          onClickLogin={onClickLogin}
          onClickRegister={onClickRegister}
          userDetail={userDetail}
          setUserDetail={setUserDetail}
        />
      </div>
      <div>
        {pageList[currentPage] === LOGIN_PAGES.ActivationCodePage && (
          <CodeVerificationWidget
            goBackPage={goBackPage}
            user_id={userDetail.id}
            onSuccessLogin={onSuccessLogin}
          />
        )}
      </div>

      <div>
        {pageList[currentPage] === LOGIN_PAGES.InputLocationPage && (
          <LocationInputTextWidget
            onSuccessLogin={onSuccessLogin}
            userDetail={userDetail}
          />
        )}
      </div>
    </div>
  );
}

export default withRouter(LoginPage);
