import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { NotFound } from "navigation/NotFound";
import { ROUTE_CONTACT_US, ROUTE_CONTACT_US_UNREGISTER, ROUTE_DASHBOARD, ROUTE_HOME, ROUTE_LOGIN, ROUTE_PRIVACY_POLICY, ROUTE_SALES_SUCCESS, ROUTE_TERMS_OF_SERVICE } from "navigation/CONSTANTS";
import PrivateRoute from "./Auth/PrivateRoute";
import TopbarLayout from "layouts/TopbarLayout/TopbarLayout";
import SidebarLayout from "layouts/SidebarLayout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { ChangeSize } from "redux/actions/appActions";
import MainLayout from "layouts/MainLayout/MainLayout";
import ContactusPage from "pages/ContactusPage/ContactusPage";
import TermsPage from "pages/TermsPage/TermsPage";
import DashboardPage from "pages/HomePage/DashboardPage";
import PrivacyPage from "pages/PrivacyPage/PrivacyPage";
import ContactusUnregisterPage from "pages/ContactusPage/ContactusUnregisterPage";
import LoginPage from "pages/LoginPage/LoginPage";
export const RouterConfig = () => {
  const screenSize = useWindowSize();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ChangeSize({ width: screenSize.width, height: screenSize.height }))
  }, [screenSize]);
  const userDataStore = useSelector(x => x.userDataStore);
  const userInfo = userDataStore.user;
  return (
    <div>
      <Switch>

        <Route exact path={ROUTE_LOGIN} component={LoginPage} />
        <Route exact path={"/"} component={LoginPage} />

        <TopbarLayout>
          <MainLayout>
            <PrivateRoute
              exact
              path={ROUTE_DASHBOARD}
              component={DashboardPage}
            />
          </MainLayout>
        </TopbarLayout>

        <Route exact path={ROUTE_TERMS_OF_SERVICE} component={TermsPage} />
        <Route exact path={ROUTE_CONTACT_US_UNREGISTER} component={ContactusUnregisterPage} />
        <Route exact path={ROUTE_PRIVACY_POLICY} component={PrivacyPage} />


      </Switch>

    </div>
  );
};




// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
