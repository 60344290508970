import { message, Spin, Tabs } from "antd";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { useEffect } from "react";
import { useState, React } from "react";
import { apiGetOrderList, apiGetSettings } from "services/orderService";
import ApprovedOrder from "./ApprovedOrder/ApprovedOrder";
import DeliveredOrder from "./DeliveredOrder/DeliveredOrder";
import "./OrderPage.css";
import UpcomingOrder from "./UpcomingOrder/UpcomingOrder";
import { socket } from "services/socketService"
import { useSelector } from "react-redux";
import { SOCKET_EVENT_DATA, SOCKET_EVENT_NAME, SOCKET_EVENT_R_APPROVED_ORDER, SOCKET_EVENT_R_CANCELLED_ORDER, SOCKET_EVENT_R_DELIVERED_ORDER, SOCKET_EVENT_R_NEW_ORDER, SOCKET_EVENT_R_PAY_CONFIRM, SOCKET_EVENT_S_APPROVED_ORDER } from "services/CONSTANTS";
import { AppleOutlined } from "@ant-design/icons";

const sound_wav_file = "/assets/sound/sound.wav";

const { TabPane } = Tabs;

function OrderPage() {


  const socketDataStore = useSelector((x) => x.socketDataStore);
  const mSocket = socket
  const audio = new Audio(sound_wav_file);

  const [isBusy, setIsBusy] = useState(false);
  const [ownCommonData, setOwnCommonData] = useState({upcomingOrderList : [], approvedOrderList : [], deliveredOrderList : []});
  const [settingData, setSettingData] = useState([]);

  useEffect(() => {
    //setIsBusy(true)
    // apiGetOrderList()
    //   .then((data) => {
    //     setIsBusy(false)
    //     getOrderData(data)
    //   })
    //   .catch((err) => {
    //     setIsBusy(false)
    //     message.error("Sorry! " + err);
    //   });

    apiGetSettings()
      .then((data) => {
        setSettingData(data)
      })
      .catch((err) => {
        message.error(err)
      });
  }, []);

  useEffect(() => {
    let data = socketDataStore[SOCKET_EVENT_DATA]
    if (data !== undefined && data !== '') {

      if (socketDataStore[SOCKET_EVENT_NAME] === SOCKET_EVENT_R_APPROVED_ORDER) {
        if (ownCommonData.upcomingOrderList === undefined) return
        let upcomingOrderList = ownCommonData.upcomingOrderList
        upcomingOrderList.splice(upcomingOrderList.findIndex(e => e.id === data.id), 1);
        let approvedOrderList = ownCommonData.approvedOrderList.concat(data)
        setOwnCommonData({ ...ownCommonData, approvedOrderList: approvedOrderList, upcomingOrderList: upcomingOrderList })
        audio.play()
      }

      else if (socketDataStore[SOCKET_EVENT_NAME] === SOCKET_EVENT_R_CANCELLED_ORDER) {
        if (ownCommonData.upcomingOrderList === undefined) return
        let upcomingOrderList = ownCommonData.upcomingOrderList
        upcomingOrderList.splice(upcomingOrderList.findIndex(e => e.id === data.id), 1);
        let approvedOrderList = ownCommonData.approvedOrderList
        approvedOrderList.splice(approvedOrderList.findIndex(e => e.id === data.id), 1);
        setOwnCommonData({ ...ownCommonData, approvedOrderList: approvedOrderList, upcomingOrderList: upcomingOrderList })
      }

      else if (socketDataStore[SOCKET_EVENT_NAME] === SOCKET_EVENT_R_DELIVERED_ORDER) {
        if (ownCommonData.approvedOrderList === undefined) return
        let approvedOrderList = ownCommonData.approvedOrderList
        approvedOrderList.splice(approvedOrderList.findIndex(e => e.id === data.id), 1);
        let deliveredOrderList = ownCommonData.deliveredOrderList.concat(data)
        setOwnCommonData({ ...ownCommonData, deliveredOrderList: deliveredOrderList, approvedOrderList: approvedOrderList })
      }

      else if (socketDataStore[SOCKET_EVENT_NAME] === SOCKET_EVENT_R_PAY_CONFIRM) {
        if (ownCommonData.deliveredOrderList === undefined) return
        ownCommonData.deliveredOrderList.splice(ownCommonData.deliveredOrderList.findIndex(e => e.id === data.id), 1);
        let deliveredOrderList = ownCommonData.deliveredOrderList.concat(data)
        setOwnCommonData({ ...ownCommonData, deliveredOrderList: deliveredOrderList })
      }

      else if (socketDataStore[SOCKET_EVENT_NAME] === SOCKET_EVENT_R_NEW_ORDER) {
        if (ownCommonData.upcomingOrderList === undefined) return
        let upcomingOrderList = ownCommonData.upcomingOrderList.concat(data)
        setOwnCommonData({ ...ownCommonData, upcomingOrderList: upcomingOrderList })
        audio.play()
      }
    }

  }, [socketDataStore])


  const playSoundAction = () => {
    audio.play()
  };

  const tabList = [
    {
      key: "1",
      label: <span>
        <AppleOutlined />
        {"Upcoming Order"}
      </span>,
      children: <UpcomingOrder
        setIsBusy={setIsBusy}
        mSocket={mSocket}
        ownCommonData={ownCommonData}
        setOwnCommonData={setOwnCommonData}
        playSoundAction={playSoundAction}
      />,
    },
    {
      key: "2",
      label: <span>
        <AppleOutlined />
        {"Approved Order"}
      </span>,
      children: <ApprovedOrder
        setIsBusy={setIsBusy}
        mSocket={mSocket}
        ownCommonData={ownCommonData}
        setOwnCommonData={setOwnCommonData}
      />,
    },
    {
      key: "3",
      label: <span>
        <AppleOutlined />
        {"Delivered Order"}
      </span>,
      children: <DeliveredOrder
        setIsBusy={setIsBusy}
        mSocket={mSocket}
        ownCommonData={ownCommonData}
        settingData={settingData}
        setOwnCommonData={setOwnCommonData}
      />,
    },
  ];



  return (
    <div>
      <Spin spinning={isBusy}>
        <Tabs defaultActiveKey="1" type="card" centered size={"large"} items={tabList}>
        </Tabs>
        {/* <Tabs defaultActiveKey="1" type="card" centered size={"large"}>
          <TabPane tab={
            <span>
              <AppleOutlined />
              {"Upcoming Order"}
            </span>
          } key="1">
            <UpcomingOrder
              setIsBusy={setIsBusy}
              mSocket={mSocket}
              ownCommonData={ownCommonData}
              setOwnCommonData={setOwnCommonData}
              playSoundAction={playSoundAction}
            />
          </TabPane>
          <TabPane tab={
            <span>
              <AppleOutlined />
              {"Approved Order"}
            </span>
          } key="2">
            <ApprovedOrder
              setIsBusy={setIsBusy}
              mSocket={mSocket}
              ownCommonData={ownCommonData}
              setOwnCommonData={setOwnCommonData}
            />
          </TabPane>
          <TabPane tab={
            <span>
              <AppleOutlined />
              {"Delivered Order"}
            </span>
          } key="3">
            <DeliveredOrder
              setIsBusy={setIsBusy}
              mSocket={mSocket}
              ownCommonData={ownCommonData}
              settingData={settingData}
              setOwnCommonData={setOwnCommonData}
            />
          </TabPane>
        </Tabs> */}
      </Spin>
    </div>
  );
}

export default OrderPage;
