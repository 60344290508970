// @flow strict

import { Input, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { confirmable } from "react-confirm";
import "./AddBaseUnitDialog.css"
function AddBaseUnitDialog(props) {
  const { show, proceed } = props;
  const { oldList } = props;
  const [value, setValue] = useState("");
  const createOneUnit = () => {
    //   proceed()
    if (value == "") return;
    const newValue = value.trim();
    setValue(newValue);
    if (
      oldList.filter((x) => x.name.toLowerCase() == newValue.toLowerCase())
        .length > 0
    ) {
      message.error(`${newValue} is already in the list`);
      return;
    }
    let result = {id: Math.floor(Date.now()), name : newValue}
    proceed({
      addedItem: result,
    });
    
  };
  
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="custom-base-unit-dlg"
      closable={true}
      maskClosable={false}
      title={`Enter New People Name`}
      onOk={createOneUnit}
    >
      <div>Please specify new name you want to add.</div>
      <Input className="normal-input" value={value} onChange={(e) => setValue(e.target.value)} />
    </Modal>
  );
}

export default confirmable(AddBaseUnitDialog);
