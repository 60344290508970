import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from 'socket.io-client';
import { SOCKET_EVENT_DATA, SOCKET_EVENT_NAME, SOCKET_EVENT_R_APPROVED_ORDER, SOCKET_EVENT_R_CANCELLED_ORDER, SOCKET_EVENT_R_DELIVERED_ORDER, SOCKET_EVENT_R_NEW_ORDER, SOCKET_EVENT_R_PAY_CONFIRM, SOCKET_URL } from "services/CONSTANTS";
import { HandleSocketEvent } from "redux/actions/socketActions";



export const socket = io(SOCKET_URL)

export const SocketService = () => {
  const dispatch = useDispatch();
  const userDataStore = useSelector(x => x.userDataStore);
  const userInfo = userDataStore.user;
  useEffect(() => {
    
    socket.on('connect', ()=>{
        console.log("socket connected!")
        clearSocketData()
    })
    
    socket.on('disconnect',()=>{
        console.log('server disconnected')
        clearSocketData()
    })
    
    socket.on('connect_error', ()=>{ setTimeout(()=>{
      if (userInfo !== undefined && userInfo.role === "0" ) {
        socket.connect()
      }
    },5000) })
    
    socket.on(SOCKET_EVENT_R_APPROVED_ORDER, (data)=>{
        var tmp = {}
        tmp[SOCKET_EVENT_DATA] = data
        tmp[SOCKET_EVENT_NAME] = SOCKET_EVENT_R_APPROVED_ORDER
        console.log("socket_data", tmp)
        dispatch(HandleSocketEvent(tmp))
        clearSocketData()
    })

    socket.on(SOCKET_EVENT_R_CANCELLED_ORDER, (data)=>{
      var tmp = {}
      tmp[SOCKET_EVENT_DATA] = data
      tmp[SOCKET_EVENT_NAME] = SOCKET_EVENT_R_CANCELLED_ORDER
      console.log("socket_data", tmp)
      dispatch(HandleSocketEvent(tmp))
      clearSocketData()
  })

    socket.on(SOCKET_EVENT_R_DELIVERED_ORDER, (data)=>{
        var tmp = {}
        tmp[SOCKET_EVENT_DATA] = data
        tmp[SOCKET_EVENT_NAME] = SOCKET_EVENT_R_DELIVERED_ORDER
        console.log("socket_data", tmp)
        dispatch(HandleSocketEvent(tmp))
        clearSocketData()
    })

    socket.on(SOCKET_EVENT_R_PAY_CONFIRM, (data)=>{
      var tmp = {}
      tmp[SOCKET_EVENT_DATA] = data
      tmp[SOCKET_EVENT_NAME] = SOCKET_EVENT_R_PAY_CONFIRM
      console.log("socket_data", tmp)
      dispatch(HandleSocketEvent(tmp))
      clearSocketData()
    })
  
    socket.on(SOCKET_EVENT_R_NEW_ORDER, (data)=>{
      var tmp = {}
      tmp[SOCKET_EVENT_DATA] = data
      tmp[SOCKET_EVENT_NAME] = SOCKET_EVENT_R_NEW_ORDER
      console.log("socket_data", tmp)
      dispatch(HandleSocketEvent(tmp))
      clearSocketData()
    })

}, []);

  const clearSocketData = ()=>{
    var tmp = {}
    tmp[SOCKET_EVENT_DATA] = ""
    tmp[SOCKET_EVENT_NAME] = ""
    dispatch(HandleSocketEvent(tmp))
  }

  return (
    <div>
      
    </div>
  );
};
