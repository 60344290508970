import React, { useState } from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import "./RecipeExtraItems.css"
import { useEffect } from 'react';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { Col, Row } from 'antd';

function RecipeExtraItems(props) {
  const { optionItemString } = props
  const [dataExtraList, setDataExtraList] = useState([]);
  useEffect(() => {
    if (optionItemString !== undefined && optionItemString !== null && optionItemString !== "") {
      const dataList = JSON.parse(optionItemString)
      setDataExtraList(dataList)
    } else {
      setDataExtraList([])
    }
  }, [optionItemString]);

  const itemUI = dataExtraList == undefined || dataExtraList.length == 0 ? <div></div> : dataExtraList.map((item, index) => {
    return (
      <div key={index}>
        <div style={{ "color": "white"}}>
          <div className='font-14' style={{ paddingLeft: 4 }}>{item.name}</div> 
          <div className='font-15' style={{ paddingLeft: 12 }}>${parseFloat(item.total_price).toFixed(2)}</div>
        </div>
      </div>
    );
  });
  return (
    <div>
      {itemUI}
    </div>
  )
}

export default RecipeExtraItems
