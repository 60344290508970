import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table,} from "antd";
import { getCurrencyInfo } from 'utils/GlobalFunctions';

const DetailTable = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, onUpdateAction, onDeleteAction, } = props;
    if (dataList === undefined) return <div />
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "Thumbnail",
        dataIndex: "thumbnail_image",
        render: (text, record) => {
            return (
                <img className="detail-widget-image" src={record.thumbnail_image}/>
            )
          },
      },
      { title: "Name", dataIndex: "name" },
      {
        title: "QTY",
        dataIndex:"enter_qty",
        sorter: {
          compare: (a, b) => a.enter_qty - b.enter_qty
        },
      },
      {
        title: "Total Cost",
        dataIndex:"total_price",
        sorter: {
          compare: (a, b) => a.total_price - b.total_price
        },
        render: text => <span>{ "$(" + getCurrencyInfo() + ") " + text}</span>,
      },
       {
        title: "Created Date",
        dataIndex: "add_timestamp",
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name),
        },
      },
      {
        title: "Updated Date",
        dataIndex: "update_timestamp",
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name),
        },
      },
    ]
    const dataSource = dataList.map((info) => {
    return { ...info, key: info.id};
    });

    return (
        <div className="list-table">
            <Table 
            size="small"
            pagination={false}
            columns={columns}
            dataSource={dataSource}
          />
        </div>
    )
}

export default withRouter(DetailTable)
