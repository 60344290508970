import { googleKey } from "config/CONSTANTS";

export const BASE_URL = "https://fnb-max.com/backend/admin";
export const SOCKET_URL = "https://www.shoproasters.com:5000";

//  export const BASE_URL = "http://127.0.0.1/cafe/admin";
//  export const SOCKET_URL = "http://127.0.0.1:5000";

//User backend base url

export const urlGetSalesList = BASE_URL + "/Sales/getSalesList";
export const urlMakePayWithPaypal = BASE_URL + "/Sales/makePayWithPaypal";
export const urlMakePayWithCash = BASE_URL + "/Sales/makePayWithCash";

export const urlContactUs = BASE_URL + "/contact-us";
export const urlUserLogin = BASE_URL + "/Main/login_chef";


export const urlGetOrderList = BASE_URL + "/Order/getOrderList";
export const urlGetOrderListDelivered = BASE_URL + "/Order/getOrderListDelivered";
export const urlGetOrderListApproved = BASE_URL + "/Order/getOrderListApproved";
export const urlGetOrderListUpcoming = BASE_URL + "/Order/getOrderListUpcoming";
export const urlOrderListWithDate = BASE_URL + "/Order/orderListWithDate";
export const urlApproveOrder = BASE_URL + "/Order/approveOrder";
export const urlCancelOrder = BASE_URL + "/Order/cancelOrder";
export const urlDeliverOrder = BASE_URL + "/Order/deliverOrder";
export const urlPaymentConfirmOrder = BASE_URL + "/Order/paymentConfirmOrder";
export const urlGetSettings = BASE_URL + "/Order/getSetting";

export const SOCKET_EVENT_NAME = "socket_event_name";
export const SOCKET_EVENT_DATA = "socket_event_data";

export const SOCKET_EVENT_R_APPROVED_ORDER = "socket_event_r_approved_order";
export const SOCKET_EVENT_S_APPROVED_ORDER = "socket_event_s_approved_order";

export const SOCKET_EVENT_R_CANCELLED_ORDER = "socket_event_r_cancelled_order";
export const SOCKET_EVENT_S_CANCELLED_ORDER = "socket_event_s_cancelled_order";

export const SOCKET_EVENT_R_DELIVERED_ORDER = "socket_event_r_delivered_order";
export const SOCKET_EVENT_S_DELIVERED_ORDER = "socket_event_s_delivered_order";

export const SOCKET_EVENT_R_PAY_CONFIRM = "socket_event_r_pay_confirm";
export const SOCKET_EVENT_S_PAY_CONFIRM = "socket_event_s_pay_confirm";

export const SOCKET_EVENT_R_NEW_ORDER = "socket_event_r_new_order";
