import React, { useState } from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import "./ExtraOptionItems.css"
import { useEffect } from 'react';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { Col, Row } from 'antd';

function ExtraOptionItems(props) {
  const { optionItemString } = props
  const [dataExtraList, setDataExtraList] = useState([]);
  useEffect(() => {
    if (optionItemString !== undefined && optionItemString !== null && optionItemString !== "") {
      const dataList = JSON.parse(optionItemString)
      setDataExtraList(dataList)
    } else {
      setDataExtraList([])
    }
  }, [optionItemString]);

  const optionListUI = (group) => {
    const list = group.optionList
    return list == undefined || list.length == 0 ? <div></div> : list.map((info, index) => {
      return (
        <div key={index}>
          {info.value && <div className='font-14'>{info.name}</div>}
        </div>
      );
    });
  }

  const itemUI = dataExtraList == undefined || dataExtraList.length == 0 ? <div></div> : dataExtraList.map((item, index) => {
    return (
      <div key={index}>
        <div style={{ "color": "white", padding: 4 }}>
          {/* <div className='font-15'>{item.name}</div>  */}
          <div style={{ paddingLeft: 0 }}>
            {optionListUI(item)}
          </div>      
        </div>
      </div>
    );
  });
  return (
    <div>
      {itemUI}
    </div>
  )
}

export default ExtraOptionItems
