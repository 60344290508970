import React from "react";
import { Button, Col, Divider, Input, Row } from "antd";
import AuthDialogWidget from "components/AuthDialogWidget/AuthDialogWidget";
import AuthLogoWidget from "components/AuthLogoWidget/AuthLogoWidget";
import NormalButton from "components/ButtonWidgets/NormalButton/NormalButton";
import TextButton from "components/ButtonWidgets/TextButton/TextButton";
import CodeVerificationWidget from "components/CodeVerificationWidget/CodeVerificationWidget";
import InputEmailOrPhone from "components/InputWidgets/InputEmailOrPhone/InputEmailOrPhone";
import InputPassword from "components/InputWidgets/InputPassword/InputPassword";
import { useState } from "react";
import { REGISTER_WITH } from "config/CONSTANTS";
import { validateEmail } from "services/commonService";
import { ROUTE_FORGOT_PASSWORD } from "navigation/CONSTANTS";
import { withRouter } from "react-router";

function InputPage(props) {
  const { userDetail, setUserDetail } = props;
  const { onClickLogin } = props;
  const [wrongList, setWrongList] = useState([]);
  const validateFields = () => {
    const { email, phone, password, register_with } = userDetail;
    // console.log(email, phone, password, register_with);
    let tmpWrongList = [];
    if (register_with === REGISTER_WITH.phone) {
      if (phone === undefined || phone === "") {
        tmpWrongList.push("phone");
      }
    } else {
      if (validateEmail(email) === false) {
        tmpWrongList.push("email");
      }
    }
    if (password === undefined || password === "") {
      tmpWrongList.push("password");
    }
    setWrongList(tmpWrongList);

    return tmpWrongList.length === 0;
  };

  const onClickNext = () => {
    if (validateFields() === false) {
      // console.log("REJECTED");
      return;
    }
    onClickLogin();
  };
  return (
    <div style={{color:'var(--blackColor)'}}>
      <Row align={"middle"}>
        <Col xs={24} sm={24} style={{ textAlign: "center" }}>
          <AuthLogoWidget />
        </Col>
        <Col xs={24} sm={24} style={{ textAlign: "center" }}>
          <AuthDialogWidget title={"Login"}>
            <div style={{ marginTop: 10 }}>
              <InputEmailOrPhone
                userDetail={userDetail}
                setUserDetail={setUserDetail}
                wrongList={wrongList}
                setWrongList={setWrongList}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <InputPassword
                userDetail={userDetail}
                setUserDetail={setUserDetail}
                onClickEnter={onClickNext}
                wrongList={wrongList}
                setWrongList={setWrongList}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <NormalButton
                style={{ backgroundColor: "var(--blueColor)" }}
                onClick={onClickNext}
              >
                Login
              </NormalButton>
            </div>
            <div style={{ marginTop: 20 }}>
              <TextButton
                style={{
                  color: "var(--blueColor)",
                  fontFamily: "regularFont",
                }}
              >
                Please enter Chef credential
              </TextButton>
            </div>
            <div>
              <Divider />
            </div>
            <div style={{ textAlign: "center" }}>
                Welcome Inventory Manage System!
            </div>
          </AuthDialogWidget>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(InputPage);
